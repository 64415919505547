// 新增
const createURL = `${API_CONFIG.controlBaseURL}subjectSection/subjectSectionAdd`
// 编辑
const editURL = `${API_CONFIG.controlBaseURL}subjectSection/subjectSectionUpdate`
// 查询详情
const queryURL = `${API_CONFIG.controlBaseURL}subjectSection/subjectSectionView`
// 删除版块
const deleteURL = `${API_CONFIG.controlBaseURL}subjectSection/subjectSectionDelete`

export {
  createURL,
  editURL,
  queryURL,
  deleteURL
}
