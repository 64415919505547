<template>
  <div class="SectionList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-select2 v-if="regionVisible" label="所属公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
        <v-input label="应用版块名称" v-model="searchParams.sectionName" :maxlength="4" />
        <v-input-number label="排序" v-model="searchParams.sectionSort" :max="999" />
        <v-select label="应用版块层级" v-model="searchParams.sectionLevel" :options="sectionLevelOps" />
        <v-select label="版块状态" v-model="searchParams.visible" :options="visibleOps" />
        <v-select label="应用版块类型" v-model="searchParams.sectionType" :options="sectionTypeOps" />
        <v-datepicker label="创建时间段" type="rangedatetimer" :maxDate="new Date()" :startTime.sync="searchParams.startIntime" :endTime.sync="searchParams.endIntime" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)" />
        <v-button v-if="deleteVisible(scope.row)" text="删除" type="text" permission="delete" @click="deleteClick(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { regionParams } from 'common/select2Params'
import { deleteURL } from './api'
import { sectionLevelMap, setSectionLevelOps, visibleMap, setVisibleOps, sectionTypeMap, setSectionTypeOps } from '../map'

export default {
  name: 'SectionList',
  props: {
    type: String,
    searchUrl: String,
    formName: String
  },
  data () {
    return {
      regionVisible: false,
      sectionLevelOps: setSectionLevelOps(1),
      visibleOps: setVisibleOps(1),
      sectionTypeOps: setSectionTypeOps(1),
      regionParams,
      searchParams: {
        regionId: '',
        sectionName: '',
        sectionSort: undefined,
        sectionLevel: undefined,
        visible: undefined,
        sectionType: undefined,
        startIntime: '',
        endIntime: ''
      },
      headers: [
        {
          prop: 'sectionName',
          label: '应用版块名称'
        },
        {
          prop: 'sectionLevelText',
          label: '应用版块层级',
          formatter: row => sectionLevelMap[row.sectionLevel]
        },
        {
          prop: 'sectionTypeText',
          label: '应用版块类型',
          formatter: row => sectionTypeMap[row.sectionType]
        },
        {
          prop: 'sectionSort',
          label: '排序',
          align: 'right'
        },
        {
          prop: 'visibleText',
          label: '版块状态',
          formatter: row => visibleMap[row.visible]
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'inuser',
          label: '创建人'
        }
      ]
    }
  },
  created () {
    if (this.type === 'tenant') {
      this.regionVisible = true
      this.headers.splice(1, 0, {
        prop: 'regionName',
        label: '所属公司'
      })
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: this.formName
      })
    },
    edit (row) {
      this.$router.push({
        name: this.formName,
        query: {
          id: row.id
        }
      })
    },
    async deleteClick (row) {
      let confirmText = '删除版块后，新建租户时将不再显示此版块，是否删除？'
      if (this.type === 'tenant') {
        confirmText = '删除版块后，在管理后台和用户APP中将不再显示，是否删除？'
      }
      let result = await this.$confirm(confirmText)
      if (result) {
        const url = `${deleteURL}/${row.id}`
        const { status, data } = await this.$axios.delete(url)
        if (status === 100) {
          this.$message.success('删除成功')
          this.$refs.list.searchData()
        }
      }
    },
    deleteVisible (row) {
      return row.sectionType === 0
    }
  }
}
</script>

<style lang="scss" scoped>
.SectionList-wrapper {
  height: 100%;
}
</style>
