var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "SectionList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _vm.regionVisible
                  ? _c(
                      "v-select2",
                      _vm._b(
                        {
                          attrs: { label: "所属公司" },
                          model: {
                            value: _vm.searchParams.regionId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "regionId", $$v)
                            },
                            expression: "searchParams.regionId",
                          },
                        },
                        "v-select2",
                        _vm.regionParams,
                        false
                      )
                    )
                  : _vm._e(),
                _c("v-input", {
                  attrs: { label: "应用版块名称", maxlength: 4 },
                  model: {
                    value: _vm.searchParams.sectionName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "sectionName", $$v)
                    },
                    expression: "searchParams.sectionName",
                  },
                }),
                _c("v-input-number", {
                  attrs: { label: "排序", max: 999 },
                  model: {
                    value: _vm.searchParams.sectionSort,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "sectionSort", $$v)
                    },
                    expression: "searchParams.sectionSort",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "应用版块层级",
                    options: _vm.sectionLevelOps,
                  },
                  model: {
                    value: _vm.searchParams.sectionLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "sectionLevel", $$v)
                    },
                    expression: "searchParams.sectionLevel",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "版块状态", options: _vm.visibleOps },
                  model: {
                    value: _vm.searchParams.visible,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "visible", $$v)
                    },
                    expression: "searchParams.visible",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "应用版块类型", options: _vm.sectionTypeOps },
                  model: {
                    value: _vm.searchParams.sectionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "sectionType", $$v)
                    },
                    expression: "searchParams.sectionType",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "创建时间段",
                    type: "rangedatetimer",
                    maxDate: new Date(),
                    startTime: _vm.searchParams.startIntime,
                    endTime: _vm.searchParams.endIntime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startIntime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startIntime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endIntime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endIntime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _vm.deleteVisible(scope.row)
                  ? _c("v-button", {
                      attrs: {
                        text: "删除",
                        type: "text",
                        permission: "delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteClick(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }